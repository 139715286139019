import React, { Fragment, useCallback, useEffect, useMemo, /* useRef, */ useState } from "react"
import DataTable from "react-data-table-component"
import Overlay from "react-bootstrap/Overlay"
import Popover from "react-bootstrap/Popover"
import { DateRangePicker } from "react-date-range"
import { toast } from "react-toastify"
import { useThemeMode } from "../../../_metronic/partials"
import axios from "axios"
import ToolbarWrapper from "../shared-components/ToolbarWrapper"
import moment from "moment/moment"
import { parseErrorToArray } from "../utils/ParseErrors"
import CustomToast from "../shared-components/CustomToast"
import "flag-icons/css/flag-icons.min.css"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { useParams } from "react-router-dom"
import { FilterComponent } from "../shared-components/FilterComponent"
// import Tooltip from "../utils/PopOverTooltip"
import Tooltip from "../utils/PopOverTooltip"
import CopyToClipboard from "react-copy-to-clipboard"
import { useAuth } from "../auth"
const API_URL = process.env.REACT_APP_API_URL

const ArrowDownward = () => <i className='las la-arrow-down' />

const LinearIndeterminate = () => (
  <div className='d-flex justify-content-center align-items-center h-350px'>
    <span className='spinner-border spinner-border-md align-middle ms-2' />
  </div>
)

const Reports = ({ toolbar = true }) => {
  const {currentUser} = useAuth()
  const { tag_id } = useParams()
  const [tagId, setTagId] = useState(tag_id)
  const theme = useThemeMode()
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortBy, setSortBy] = useState("date")
  const [sortOrder, setSortOrder] = useState("desc")
  const [selectedValue, setSelectedValue] = useState([])
  const [filters, setFilters] = useState([])
  let filterOptions = [
    {
      label: "Tag id",
      key: "tag_id"
    },
    {
      label: "Tag name",
      key: "tag_name"
    },
    {
      label: "Users",
      key: "users"
    }
  ]

  if(currentUser.role_id == 2) {
    filterOptions = [
      {
        label: "Tag id",
        key: "tag_id"
      },
      {
        label: "Tag name",
        key: "tag_name"
      },
    ]
  }

  // const [searchTerm, setSearchTerm] = useState("")
  // const [selectedValue, setSelectedValue] = useState([])

  const [showRange, setShowRange] = useState(false)
  const [target, setTarget] = useState(null)

  const today = new Date()
  const currentDay = today.getDay()
  const start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - currentDay + 1)

  const end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6)

  const lastMonday = new Date(start.getFullYear(), start.getMonth(), start.getDate() - 7)

  const months = lastMonday.getMonth() !== today.getMonth() ? 2 : 1

  const [dateRange, setDateRange] = useState([{ startDate: start, endDate: end, key: "selection" }])

  const updateDateRange = item => {
    setDateRange([item?.selection])
  }

  const setDatePreview = () => {
    const selectedStartDate = moment(dateRange[0].startDate)
    const selectedEndDate = moment(dateRange[0].endDate)
    const today = moment().startOf("day")
    const yesterday = moment().subtract(1, "day").startOf("day")
    const startOfWeek = moment().startOf("week")
    const endOfWeek = moment().endOf("week")
    const lastWeekStart = moment().subtract(1, "week").startOf("week")
    const lastWeekEnd = moment().subtract(1, "week").endOf("week")
    const startOfMonth = moment().startOf("month")
    const endOfMonth = moment().endOf("month")
    const lastMonthStart = moment().subtract(1, "month").startOf("month")
    const lastMonthEnd = moment().subtract(1, "month").endOf("month")

    if (selectedStartDate.isSame(today, "day") && selectedEndDate.isSame(today, "day")) {
      return "Today"
    } else if (selectedStartDate.isSame(yesterday, "day") && selectedEndDate.isSame(yesterday, "day")) {
      return "Yesterday"
    } else if (selectedStartDate.isSame(startOfWeek, "day") && selectedEndDate.isSame(endOfWeek, "day")) {
      return "This Week"
    } else if (selectedStartDate.isSame(lastWeekStart, "day") && selectedEndDate.isSame(lastWeekEnd, "day")) {
      return "Last Week"
    } else if (selectedStartDate.isSame(startOfMonth, "day") && selectedEndDate.isSame(endOfMonth, "day")) {
      return "This Month"
    } else if (selectedStartDate.isSame(lastMonthStart, "day") && selectedEndDate.isSame(lastMonthEnd, "day")) {
      return "Last Month"
    } else if (selectedStartDate.isSame(start, "day") && selectedEndDate.isSame(end, "day")) {
      return "This Week"
    } else {
      // Custom format for manually set date ranges
      const formattedStartDate = selectedStartDate.format("Do MMM YY")
      const formattedEndDate = selectedEndDate.format("Do MMM YY")
      return `${formattedStartDate} - ${formattedEndDate}`
    }
  }

  const handleClick = event => {
    setShowRange(!showRange)
    setTarget(event.target)
  }
  const handleCopy = (text, result) => {
    toast.info(<CustomToast messages={[text]} />)
  }
  const handlePageChange = page => setCurrentPage(page)

  const fetchReports = useCallback(
    async (page, dr = dateRange, filters) => {
      const selectedStartDate = moment(dr[0].startDate).format("YYYY-M-D")
      const selectedEndDate = moment(dr[0].endDate).format("YYYY-M-D")
      const f = filters.map(obj => ({ key: obj.key, value: obj.values }))
      setLoading(true)
      return axios
        .get(`${API_URL}/impressions`, {
          params: {
            // tag_id: tagId,
            start_date: selectedStartDate,
            end_date: selectedEndDate,
            limit: perPage,
            page,
            order: sortOrder,
            sort: sortBy,
            filters: JSON.stringify(f)
          }
        })
        .then(({ data }) => data)
        .then(response => {
          const { data, success } = response
          if (success) {
            const impressions = data?.impressions
            if (impressions && impressions.length) {
              impressions.map(obj => {
                obj.blocked_counts = obj.total_count - obj.served_count
                obj.cost = ((parseFloat(obj.total_count) / 1000) * parseFloat(data?.pricing)).toFixed(2)
                return obj
              })
              setData(impressions)
            } else {
              setData([])
            }
            setTotalRows(data?.payload?.total)
          } else {
            setData([])
          }
          setLoading(false)
        })
        .catch(err => {
          setData([])
          toast.error(<CustomToast messages={parseErrorToArray(err)} />)
        })
        .finally(() => setLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedValue]
  )
  const handleSort = (column, direction) => {
    const { selector } = column

    setData(prevData => customSort([...prevData], selector, direction))
  }

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = selector(rowA)
      const bField = selector(rowB)

      let comparison = 0

      if (aField > bField) {
        comparison = 1
      } else if (aField < bField) {
        comparison = -1
      }

      return direction === "desc" ? comparison * -1 : comparison
    })
  }

  const columns = useMemo(
    () => [
      {
        name: "Date",
        selector: row => row?.date,
        sortable: true,
        sortField: "date"
        // sortFunction:sortByDate
      },
      {
        name: "Tag ID",
        width: "250px",
        selector: row => row?.tag_id,
        cell: (row, i, column) => {
          return (
            <>
              <Tooltip position='bottom' title={"Click to copy Tag id"}>
                <CopyToClipboard text={row.tag_id} onCopy={()=>handleCopy("Tag id copied successfully")} options={{ message: "Tag id copied" }}>
                  <div className='py-1 pt-1' style={{ cursor: "copy" }}>
                    <span>{row.tag_id}</span>
                    <button className='btn btn-light-primary btn-icon btn-flat btn-sm h-20px w-20px mx-2 br-none'>
                      <span className='las la-copy fs-8 '></span>
                    </button>
                  </div>
                </CopyToClipboard>
              </Tooltip>
            </>
          )
        }
      },

      {
        name: "Source id",
        selector: row => row?.source_id,
        width: "300px",
        cell: (row, i, column) => {
          const chunkSize = 30
          const concatenatedHash = row.source_id.match(new RegExp(`.{1,${chunkSize}}`, "g")).join("\n")
          const str = row.source_id.substring(0, 30) + "..."
          return (
            <>
              <Tooltip position='bottom' title={"Click to copy source id"}>
                <CopyToClipboard text={row.source_id}  onCopy={()=>handleCopy("Source id copied successfully")} options={{ message: "Source id copied" }}>
                  <div className='py-1 pt-1' style={{ cursor: "copy" }}>
                    <span>{concatenatedHash}</span>
                    <button className='btn btn-light-primary btn-icon btn-flat btn-sm h-20px w-20px mx-2 br-none'>
                      <span className='las la-copy fs-8 '></span>
                    </button>
                  </div>
                </CopyToClipboard>
              </Tooltip>
            </>
          )
        }
      },
      {
        name: "Total Count",
        selector: row => row?.total_count,
        center: false,
        sortable: true,
        sortField: "total_count",
        width: "150px"
      },
      {
        name: "Served Counts",
        selector: row => row?.served_count,
        sortable: true,
        sortField: "served_count",
        width: "150px",
        cell: (row, i, column) => {
          const total = parseFloat(row.total_count)
          const served = parseFloat(row.served_count)
          const servedPercentage = (served / total) * 100
          return row.served_count + " (" + servedPercentage.toFixed(2) + "%)"
        }
      },
      {
        name: "Blocked Counts",
        width: "150px",
        selector: row => row?.served_count,
        sortable: true,
        sortField: "blocked_counts",
        cell: (row, i, column) => {
          const total = parseFloat(row.total_count)
          const served = parseFloat(row.served_count)
          const blocked = total - served
          const blocked_percentage = (blocked / total) * 100
          return row.blocked_counts + " (" + blocked_percentage.toFixed(2) + "%)"
        }
      },
      {
        name: "Bots Count",
        center: true,
        width: "150px",
        selector: row => row?.bots_count,
        sortable: true,
        sortField: "bots_count",
        cell: (row, i, column) => {
          const total = parseFloat(row.total_count)
          const served = parseFloat(row.bots_count)
          const servedPercentage = (served / total) * 100
          return row.bots_count + " (" + servedPercentage.toFixed(2) + "%)"
        }
      },
      {
        name: "Cost",
        center: false,
        selector: row => row?.cost,
        sortable: true,
        sortField: "cost",
        cell: (row, i, column) => "$" + row.cost
      }
    ],
    []
  )

  useEffect(() => {
    fetchReports(currentPage, dateRange, selectedValue)
  }, [fetchReports, currentPage, selectedValue])

  useEffect(() => {
    if (tagId && tagId != "") {
      setSelectedValue([
        {
          key: "tag_id",
          values: [tagId],
          label: "Tag id"
        }
      ])
    }
  }, [tagId])

  const CustomFooter = () => {
    const total = data?.reduce((sum, item) => sum + item.total_count, 0)
    const served = data?.reduce((sum, item) => sum + item.served_count, 0)
    const blocked = data?.reduce((sum, item) => sum + item.blocked_counts, 0)
    const bots = data?.reduce((sum, item) => sum + item.bots_count, 0)
    const cost = data?.reduce((sum, item) => sum + parseFloat(item.cost), 0).toFixed(2)

    

    let totalServed = 0
    let totalCounts = 0
    let totalBlocked = 0
    let totalBots = 0

    data?.forEach(entry => {
      totalServed += entry.served_count
      totalCounts += entry.total_count
      totalBlocked += entry.blocked_counts
      totalBots += entry.bots_count
    })
    

    let totalPercentageServed = (totalServed / totalCounts) * 100
    let totalPercentageBlocked = (totalBlocked / totalCounts) * 100
    let totalBotPercentage = (totalBots / totalCounts) * 100

    if (isNaN(totalPercentageServed)) {
      totalPercentageServed = 0
    } 

    if (isNaN(totalPercentageBlocked)) {
      totalPercentageBlocked = 0
    } 

    if (isNaN(totalBotPercentage)) {
      totalBotPercentage = 0
    } 

    return (
      <div className='row gy-0 g-xl-10'>
        <div className='col-sm-6 col-lg mb-xl-1'>
          <div className='card h-lg-60' style={{ border: "1px solid #eee" }}>
            <div className='card-body d-flex bg-light-primdary justify-content-between align-items-start flex-column'>
              <div className='d-flex flex-column '>
                <div className='d-flex align-items-center'>
                  <span className='fs-2x fw-bold text-gray-900 me-2 lh-1 ls-n2'>{total}</span>
                  <span className='badge badge-light-primary fs-base'></span>
                </div>
                <div className='m-0'>
                  <span className='fw-semibold fs-5 text-gray-500'>Total counts</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-sm-6 col-lg mb-xl-1'>
          <div className='card h-lg-60' style={{ border: "1px solid #eee" }}>
            <div className='card-body d-flex bg-light-primdary justify-content-between align-items-start flex-column'>
              <div className='d-flex flex-column '>
                <div className='d-flex align-items-center'>
                  <span className='fs-2x fw-bold text-gray-900 me-2 lh-1 ls-n2'>{served}</span>
                  <span className='badge badge-light-primary fs-base'>{totalPercentageServed.toFixed(2)}%</span>
                </div>
                <div className='m-0'>
                  <span className='fw-semibold fs-5 text-gray-500'>Served counts</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-sm-6 col-lg mb-xl-1'>
          <div className='card h-lg-60' style={{ border: "1px solid #eee" }}>
            <div className='card-body d-flex bg-light-primdary justify-content-between align-items-start flex-column'>
              <div className='d-flex flex-column '>
                <div className='d-flex align-items-center'>
                  <span className='fs-2x fw-bold text-gray-900 me-2 lh-1 ls-n2'>{blocked}</span>
                  <span className='badge badge-light-primary fs-base'>{totalPercentageBlocked.toFixed(2)}%</span>
                </div>
                <div className='m-0'>
                  <span className='fw-semibold fs-5 text-gray-500'>Blocked counts</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-sm-6 col-lg mb-xl-1'>
          <div className='card h-lg-60' style={{ border: "1px solid #eee" }}>
            <div className='card-body d-flex bg-light-primdary justify-content-between align-items-start flex-column'>
              <div className='d-flex flex-column '>
                <div className='d-flex align-items-center'>
                  <span className='fs-2x fw-bold text-gray-900 me-2 lh-1 ls-n2'>{bots}</span>
                  <span className='badge badge-light-primary fs-base'>{totalBotPercentage.toFixed(2)}%</span>
                </div>
                <div className='m-0'>
                  <span className='fw-semibold fs-5 text-gray-500'>Bots counts</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-6 col-lg mb-xl-1'>
          <div className='card h-lg-60' style={{ border: "1px solid #eee" }}>
            <div className='card-body d-flex justify-content-between align-items-start flex-column'>
              <div className='d-flex flex-column '>
                <span className='fw-semibold fs-2x text-gray-800 lh-1 ls-n2'>${cost}</span>
                <div className='m-0'>
                  <span className='fw-semibold fs-5 text-gray-500'>Cost</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      {toolbar && (
        <ToolbarWrapper>
          <div className='d-flex justify-content-center align-items-center gap-4'>
            <h3>Reports</h3>
          </div>
        </ToolbarWrapper>
      )}
      <div className='d-flex justify-content-between align-items-center gap-3 mb-5'>
        <div className='d-flex justify-content-center align-items-center'>
          {/* <span className='fs-6 fw-semibold text-muted flex-shrink-0 pe-4 d-none d-md-block' /> */}
          <button
            className='btn btn-sm btn-white btn-outline btn-color-gray-700 btn-active-color-primary btn-active-light border-color-grey-700 fw-semibold fs-7'
            onClick={handleClick}
          >
            <i className='las la-calendar-alt fs-2' /> {setDatePreview()}
          </button>
          <Overlay show={showRange} target={target} placement='bottom-start' rootClose onHide={() => setShowRange(false)}>
            <Popover id='popover-contained'>
              <Popover.Body style={{ display: "inline" }}>
                <DateRangePicker
                  onChange={item => updateDateRange(item)}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={months}
                  maxDate={new Date()}
                  rangeColors={["#009ef7"]}
                  color='#009ef7'
                  ranges={dateRange}
                  direction='horizontal'
                />
              </Popover.Body>
            </Popover>
          </Overlay>
        </div>

        <div className='d-flex justify-content-start align-items-center flex-grow-1 gap-3'>
          <div className=' pl-0'>
            <FilterComponent
              filters={filterOptions}
              dataUrl={`${API_URL}/impressions/select`}
              fetchData={fetchReports}
              selectedFilterValues={selectedValue}
              setSelectedFilterValues={setSelectedValue}
            />
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <button type='button' className='btn btn-sm btn-primary px-3 ' onClick={() => fetchReports(1, dateRange, selectedValue)}>
            <i className='fa-solid fa-magnifying-glass pe-0' /> Search Report
          </button>
        </div>
      </div>
      <CustomFooter />

      <div className={`mb-5 mb-xl-8 mt-5`}>
        <DataTable
          pagination
          fixedHeader
          columnsResize
          persistTableHead
          data={data}
          columns={columns}
          theme={theme.mode}
          onSort={handleSort}
          progressPending={loading}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={setPerPage}
          sortIcon={<ArrowDownward />}
          progressComponent={<LinearIndeterminate />}
          style={{ overflowX: "auto", minHeight: "500px" }}
          customStyles={{ tableWrapper: { style: { display: "grid", overflowX: "scroll" } } }}
          footer={<CustomFooter />}
        />
        {/* <CustomFooter/> */}
      </div>
    </Fragment>
  )
}

export default Reports
