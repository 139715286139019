import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import clsx from "clsx"
import { KTSVG, useDebounce } from "../../../_metronic/helpers"
import DataTable from "react-data-table-component"
// import { deleteOffer } from "./core/_requests"
import { toast } from "react-toastify"
import { Search, useThemeMode } from "../../../_metronic/partials"
import axios from "axios"
import ToolbarWrapper from "../shared-components/ToolbarWrapper"
import { Can /* , useCasl */ } from "../auth/CaslContext"
import "flag-icons/css/flag-icons.min.css"
import CustomToast from "../shared-components/CustomToast"
import { parseErrorToArray } from "../utils/ParseErrors"

const statusOptions = [
  { label: "Disabled", value: "disabled" },
  { label: "Active", value: "active" }
]

const API_URL = process.env.REACT_APP_API_URL
const ArrowDownward = () => <i className='las la-arrow-down' />
const LinearIndeterminate = () => (
  <div className='d-flex justify-content-center align-items-center h-350px'>
    <span className='spinner-border spinner-border-md align-middle ms-2' />
  </div>
)

const UsersTable = ({ toolbar = true }) => {
  const statusDropdownRef = useRef(null)
  const statusDropdownBtnRef = useRef(null)
  const theme = useThemeMode()
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortBy, setSortBy] = useState("id")
  const [sortOrder, setSortOrder] = useState("desc")
  const [searchTerm, setSearchTerm] = useState("")
  const [status, setStatus] = useState(statusOptions[0])
  const debouncedSearch = useDebounce(searchTerm, 500)
  const [filterCountries, setFilterCountries] = useState([])
  const [os, setOs] = useState([])

  const handlePageChange = page => setCurrentPage(page)

  const fetchOffers = useCallback(
    async (page, filters = [], searchText, filterCountries, os) => {
      setLoading(true)
      return axios
        .get(`${API_URL}/users`, {
          params: {
            limit: perPage,
            page,
            order: sortOrder,
            sort: sortBy,
            status: status.value,
            start: (page - 1) * perPage,
            search:searchText
          }
        })
        .then(({ data }) => data)
        .then(response => {
          const { data, success } = response
          if (success) {
            setData(data?.users)
            setTotalRows(data?.payload?.pagination?.total)
          }
        })
        .catch(err => {
          console.error(err.message)
          toast.error(<CustomToast messages={parseErrorToArray(err)} />)
        })
        .finally(() => setLoading(false))
    },
    [perPage, sortOrder, sortBy, status]
  )

  const handleSort = (column, sortDirection) => {
    setSortBy(column.sortField)
    setSortOrder(sortDirection)
  }

  useEffect(() => {
    if (statusDropdownBtnRef.current) statusDropdownBtnRef.current.classList.remove("show", "menu-dropdown")
    if (statusDropdownRef.current) {
      statusDropdownRef.current.classList.remove("show")
      statusDropdownRef.current.removeAttribute("style")
    }
  }, [status])

  useEffect(() => {
    fetchOffers(currentPage, [], searchTerm, filterCountries, os)
  }, [currentPage, fetchOffers, status])

  const columns = [
    {
      name: "Id",
      selector: row => `${row.id}`,
      sortable: true,
      sortField: "id",
      width: "30px",
      cell: (row, i) => row.id
    },
    {
      name: "Name",
      selector: row => `${row.name}`
    },
    {
      name: "Email",
      selector: row => `${row.email}`,
      width: "150px",
      style: { display: "flex", justifyContent: "space-between", wordWrap: "break-word" },
      cell: (row, i, column) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap ",
            textOverflow: "ellipsis ",
            width: "130px" // You can adjust the maxWidth as needed
          }}
        >
          {`${row?.email}`}
        </div>
      )
    },
    {
      name: "Role",
      selector: row => `${row.email}`,
      cell: (row, i, column) => {
        if (row.role_id == "1") {
          return <label className='badge badge-light-primary badge-sm'>Admin</label>
        } else {
          return <label className='badge badge-light-primary badge-sm'>Company</label>
        }
      }
    },
    {
      name: "Api URL",
      selector: row => `${row.email}`,
      width: "300px",
      style: { display: "flex", justifyContent: "space-between" },
      cell: (row, i, column) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "hidden"
            }}
          >
            <input
              type='text'
              className='form-control form-control-sm'
              disabled
              value={row?.api_url}
              style={{ width: "250px", whiteSpace: "nowrap" }}
            />
          </div>
        </>
      )
    },
    {
      name: "Status",
      style: { wordWrap: "break-word", textWrap: "wrap" },
      cell: (row, i, column) => {
        if (row.status == "active") {
          return <label className='badge badge-light-success badge-sm'>Active</label>
        } else {
          return <label className='badge badge-light-danger badge-sm'>In-Active</label>
        }
      }
    },
    {
      name: "Domain",
      selector: row => `${row.domain}`,
      cell: (row, i, column) => {
        return row.domain
      }
    },
    {
      name: "Pricing",
      selector: row => `${row.country}`,
      center: true,
      style: { flexWrap: "wrap", justifyContent: "center", fontWeight: 600 },
      cell: (row, i, column) => {
        return "$ " + row?.pricing
      }
    },
    {
      name: "CDN",
      selector: row => `${row.cdn}`,
      center: true,
      cell: row => {
        return <label className='badge badge-light-dark badge-sm'>{row?.cdn}</label>
      }
    },
    {
      name: "CDN Path",
      selector: row => `${row.updated_at}`,
      style: { flexWrap: "wrap", justifyContent: "left", wordWrap: "break-word" },
      width: "150px",
      cell: (row, i, column) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap ",
            textOverflow: "ellipsis ",
            width: "130px" // You can adjust the maxWidth as needed
          }}
        >
          {`${row?.cdn_path}`}
        </div>
      )
    },
    {
      name: "Actions",
      center: true,
      cell: row => {
        const Actions = offer => {
          return (
            <div className='d-flex flex-col gap-1 w-100 align-items-center justify-content-center'>
              <Can do='edit' this='offers'>
                <Link to={"/admin/user/" + offer.id} className='btn btn-sm btn-icon btn-light-primary w-30px h-25px'>
                  <span className='las la-edit fs-5' />
                </Link>
              </Can>
            </div>
          )
        }
        return Actions(row)
      }
    }
  ]

  return (
    <Fragment>
      <ToolbarWrapper>
        <div className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
          <h3>Users</h3>
        </div>
        <div className='d-flex flex-row align-items-center gap-3 min-w-300px justify-content-end'>
          <div>
            <button
              ref={statusDropdownBtnRef}
              data-kt-menu-trigger="{default: 'hover'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              className='btn btn-sm btn-light-primary btn-active-light-primary'
            >
              <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-5' />
              {status?.label || "All"}
            </button>
            <div
              ref={statusDropdownRef}
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
              data-kt-menu='true'
            >
              {statusOptions.map((obj, i) => (
                <div
                  className={clsx("menu-item px-3 bg-hover-light-primary", {
                    "bg-light-primary hoverable": status && status.value === obj.value
                  })}
                  key={`filter_option_${i}`}
                >
                  <div className='menu-content d-flex align-items-center px-3 cursor-pointer' onClick={e => setStatus(obj)}>
                    {obj.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Link to={"/admin/user/add"} className='btn btn-sm btn-light-primary btn-active-light-primary'>
            Add user
          </Link>
        </div>
      </ToolbarWrapper>

      <div className='row mb-5'>
        <div className='col-lg-3 mr-0'>
          <div className='position-relative'>
            <div className='input-group input-group-solid border border-color-gray-700'>
              <span className='input-group-text ps-6 bg-white border-0 cursor-pointer'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-3 svg-icon-gray-00 position-absolute top-50 translate-middle'
                />
              </span>
              <input
                type='text'
                className='form-control bg-white border-0'
                placeholder='Search by email and user id'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={e => e.key === "Enter" && fetchOffers(1, [], searchTerm, filterCountries, os)}
              />

              {searchTerm?.length > 0 && (
                <span className='input-group-text ps-6 bg-white cursor-pointer' onClick={() => setSearchTerm("")}>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr015.svg'
                    className='svg-icon-3 position-absolute bg-body text-danger translate-middle top-50'
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`mb-5 mb-xl-8`}>
        <DataTable
          pagination
          fixedHeader
          columnsResize
          persistTableHead
          paginationServer
          data={data}
          columns={columns}
          theme={theme.mode}
          onSort={handleSort}
          progressPending={loading}
          paginationRowsPerPageOptions={[5, 10, 15, 25, 50]}
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setPerPage}
          sortIcon={<ArrowDownward />}
          progressComponent={<LinearIndeterminate />}
          style={{ overflowX: "auto", minHeight: "500px" }}
          customStyles={{ tableWrapper: { style: { display: "grid", overflowX: "scroll" } } }}
        />
      </div>
    </Fragment>
  )
}

export default UsersTable
