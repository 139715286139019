import { Link } from "react-router-dom"
import { useAuth } from "../../../../app/modules/auth"
// import { Languages } from "./Languages"
const HeaderUserMenu = () => {
  const { currentUser } = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3' >
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex flex-column justify-content-center align-items-start fs-5'>
              <span className='flex-grow-1'>{currentUser?.name}</span>
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1'>{currentUser?.role_text}</span>
            </div>
            <span className='fw-bold text-muted text-hover-primary fs-7'>{currentUser?.email}</span>
          </div>
        </div>
      </div>

      <div className='separator my-2' />

      {/* <Languages /> */}

      <div className='menu-item px-5 my-1'>
        <Link to='/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>

      <div className='menu-item px-5'>
        <Link to='/logout' className='menu-link px-5'>
          Sign Out
        </Link>
      </div>
    </div>
  )
}
export { HeaderUserMenu }
