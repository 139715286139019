import React from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import Roles from "./Roles"
import { lazy } from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import UsersTable from "./Users"
import { useAuth } from "../auth"
import { toast } from "react-toastify"
import CustomToast from "../shared-components/CustomToast"
const CreateUserLazy = lazy(() => import("./User/CreateUser"))
const EditUserLazy = lazy(() => import("./User/EditUser"))
const usersBreadcrumbs = [
  {
    title: "Add User",
    path: "/admin/users/add",
    isSeparator: false,
    isActive: false
  },
  {
    title: "Users",
    path: "/admin/users",
    isSeparator: false,
    isActive: false
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false
  }
]

const PrivateRoute = ({ children, requiredRole }) => {
  const { currentUser } = useAuth()
  if (currentUser.role_id == requiredRole) {
    return <>{children}</>
  } else {
    toast.error(<CustomToast messages={["You are not authorised to use this page"]} />)
    return <Navigate to='/dashboard' />
  }
}

const AdminPages = () => {
  return (
    <Routes>
      <Route
        path='user/add'
        element={
          <PrivateRoute requiredRole={1}>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Create User</PageTitle>
              <CreateUserLazy />
          </PrivateRoute>
        }
      />
      <Route
        path='user/:user_id'
        element={
          <PrivateRoute requiredRole={1}>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Create User</PageTitle>
              <EditUserLazy />
          </PrivateRoute>
        }
      />
      <Route
        path='users'
        element={
          <PrivateRoute requiredRole={1}>
              <UsersTable />
          </PrivateRoute>
        }
      />
    </Routes>
  )
}
export default AdminPages
