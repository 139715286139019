import React, { lazy, Suspense } from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import { MasterLayout } from "../../_metronic/layout/MasterLayout"
import TopBarProgress from "react-topbar-progress-indicator"
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils"
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper"
import Reports from "../modules/Reports/Reports"
import AdminPages from "../modules/admin/AdminPages"
import { useAuth } from "../modules/auth"
// import { useCasl } from "../modules/auth/CaslContext"

const PrivateRoutes = () => {
  const { currentUser } = useAuth()
  console.log(currentUser)

  // const ability = useCasl()
  // const UsersPage = lazy(() => import("../modules/apps/user-management/UsersPage"))
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"))
  const OfferPages = lazy(() => import("../modules/offers/OfferPages"))
  const TagsPages = lazy(() => import("../modules/tags/TagsPages"))
  const SourcePages = lazy(() => import("../modules/source/SourcePages"))
  const Reports = lazy(() => import("../modules/Reports/Reports"))
  const AdminPages = lazy(() => import("../modules/admin/AdminPages"))
  // const PlacementPages = lazy(() => import("../modules/publishers/PlacementPages"))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard/*' element={getSuspensedView(<DashboardWrapper />)} />

        <Route path='account/*' element={getSuspensedView(<AccountPage />)} />

        {/* Lazy Modules */}
        <Route path='offers/*' element={getSuspensedView(<OfferPages />)} />

        <Route path='/tags/*' element={getSuspensedView(<TagsPages />)} />
        <Route path='/source/*' element={getSuspensedView(<SourcePages />)} />
        <Route path='/reports/:tag_id?' element={getSuspensedView(<Reports />)} />
        <Route path='/admin/*' 
            element={getSuspensedView(<AdminPages />)} 
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='error/404' />} />
      </Route>
    </Routes>
  )
}
const SuspensedView = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary")
  TopBarProgress.config({
    barColors: {
      0: baseColor
    },
    barThickness: 2,
    shadowBlur: 5
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

function getSuspensedView(comp) {
  return <SuspensedView>{comp}</SuspensedView>
}

export { PrivateRoutes }
