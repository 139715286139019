import { useEffect } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useThemeMode } from "../../../_metronic/partials"

const StatusTooltip = props => {
  const { mode: themeMode } = useThemeMode()

  useEffect(() => {
    let tooltipStyleContainer = document.getElementById("tooltip-style-container")
    let tooltipStyle = document.querySelector("#tooltip-style-container > style")

    if (!tooltipStyleContainer) {
      tooltipStyleContainer = document.createElement("div")
      tooltipStyleContainer.id = "tooltip-style-container"
    }
    if (!tooltipStyle) {
      tooltipStyle = document.createElement("style")
      tooltipStyle.type = "text/css"
    }

    tooltipStyle.innerText = themeMode !== "dark" ? `.tooltip { --bs-tooltip-bg: #2B2B40; --bs-tooltip-color: #F2F1F3;}` : ""

    tooltipStyleContainer.appendChild(tooltipStyle)
    document.body.prepend(tooltipStyleContainer)
  }, [themeMode])

  return (
    <OverlayTrigger
      defaultShow={props?.show}
      placement={props.position ?? "right"}
      overlay={
        <Tooltip id={`tooltip-${Math.round(Math.random() * 100)}`}>
          {props?.title && <strong>{props.title}</strong>}
          {props?.body && <p className='text-primary'>{props.body}</p>}
          {props?.component}
        </Tooltip>
      }
    >
      {/* <span className={`bullet bullet-dot me-5 h-10px w-10px ${props.statusClass}`} /> */}
      {props.children}
    </OverlayTrigger>
  )
}

export default StatusTooltip
