import { Fragment } from "react"
import { useIntl } from "react-intl"
import { PageTitle } from "../../../_metronic/layout/core"

import OfferTable from "../../modules/offers/OfferTable"
import TagsTable from "../../modules/tags/TagsTable"

const DashboardPage = () => {
  return (
    <Fragment>
      <div className='col-xl-12'>
        <OfferTable toolbar={false} />
      </div>
    </Fragment>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: "MENU.DASHBOARD" })}</PageTitle>
      <DashboardPage />
    </>
  )
}
export { DashboardWrapper }
