import { useIntl } from "react-intl"
import { MenuItem } from "./MenuItem"
import { useAuth } from "../../../../../app/modules/auth/core/Auth"

export function MenuInner() {
  const intl = useIntl()
  const { currentUser } = useAuth()
  console.log (currentUser)
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: "MENU.DASHBOARD" })} to='/dashboard' />
      <MenuItem title={"Tags"} to='/tags' />
      <MenuItem title={"Source"} to='/source' />
      <MenuItem title={"Reports"} to='/reports' />
      {
        currentUser?.role_id == 1 && ( 
          <MenuItem title={"Manage users"} to='/admin/users' />
       )
      }
    </>
  )
}
