/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import { Routes, Route, Navigate, Outlet } from "react-router-dom"
import { PrivateRoutes } from "./PrivateRoutes"
import { ErrorsPage } from "../modules/errors/ErrorsPage"
import { Logout, AuthPage, useAuth } from "../modules/auth"
import { App } from "../App"
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const SpinningLoader = () => (
  <div className='loading-overlay'>
    <span className='spinner-border spinner-border-md align-middle ms-2' />
  </div>
)

const ProtectedRoute = props => {
  const { currentUser } = useAuth()
  return currentUser ? <Outlet /> : <Navigate to='/auth/login' state={window.location.pathname} />
}

const AppRoutes = () => {
  const { currentUser, loading } = useAuth()

  if (loading) return <SpinningLoader />

  return (
    <Routes>
      <Route element={<App />}>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />

        <Route element={<ProtectedRoute />}>
          <Route path='/*' element={<PrivateRoutes />} />
          <Route index element={<Navigate to='/dashboard' />} />
        </Route>

        {!currentUser && <Route path='auth/*' element={<AuthPage />} />}
      </Route>
    </Routes>
  )
}


export { AppRoutes }
