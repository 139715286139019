import React, { useEffect, useRef, useState, Fragment } from "react"
import { KTSVG, useDebounce } from "../../../_metronic/helpers"
import axios from "axios"

const FilterComponent = ({ filters, fetchData, dataUrl, dataReqOptions = {}, selectedFilterValues, setSelectedFilterValues }) => {
  const [menuState, setMenuState] = useState(1)
  const [model, setModel] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState(selectedFilterValues)
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [filterOptions, setFilterOptions] = useState([])
  const [modelStyle, setModelStyle] = useState({ position: "absolute", zIndex: 141 })
  const [searchLoading, setSearchLoading] = useState(false)

  const ref = useRef(null)
  const parentRef = useRef(null)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const goBack = () => {
    setMenuState(1)
    setSelectedFilter(null)
    setSearchTerm("")
  }

  const checkIfSelected = (cb, f) => {
    if (selectedFilters && selectedFilters.length && selectedFilter) {
      const selectedFilterItem = selectedFilters.find(obj => obj.key === selectedFilter.key)
      if (selectedFilterItem) {
        const fv = selectedFilterItem.values
        return fv.includes(cb)
      }
    }
    return false
  }

  const updateFilters = selected => {
    const selectedItem = selectedFilters.find(obj => obj.key === selectedFilter.key)
    if (!selectedItem) {
      let newFilter = { ...selectedFilter, values: [selected] }
      setSelectedFilters(prevFilters => [...prevFilters, newFilter])
    } else {
      setSelectedFilters(prevFilters =>
        prevFilters.reduce((acc, curr) => {
          if (curr.key === selectedFilter.key) {
            if (curr.values.includes(selected)) curr.values = curr.values.filter(preSelected => preSelected !== selected)
            else curr.values.push(selected)
          }

          if (!curr.values.length) return acc
          return [...acc, curr]
        }, []),
      )
    }
  }

  const addSelectedFilter = filter => {
    setSelectedFilter(filter)
    setSearchLoading(true)
    setLoading(true)
    axios
      .get(`${dataUrl}`, { params: { ...dataReqOptions, type: filter.key, q: searchTerm } })
      .then(({ data }) => {
        if (data.success && data.data) {
          // setFilterOptions(data.data)
          const options = data.data
          if (selectedFilter) {
            let selectedOptions = options.filter(option => filter.values?.includes(option.value))
            let unselectedOptions = options.filter(option => !filter.values?.includes(option.value))
            setFilterOptions([...selectedOptions, ...unselectedOptions])
          } else {
            setFilterOptions(options)
          }
        }
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
        setSearchLoading(false)
      })
    setMenuState(2)
  }

  const apply = (page, values) => {
    setModel(false)
    setMenuState("")
    setSearchTerm("")
    setSelectedFilterValues(values)
    // fetchData(1, values)
  }

  const openFilterModel = (e, f = null) => {
    e.preventDefault()
    const parentPos = document.getElementById("filter-parent").getBoundingClientRect()
    const childPos = e.target.getBoundingClientRect()
    setModelStyle({
      position:"absolute",
      zIndex:141,
      transform: `translate3d(${childPos.left - parentPos.left}px, ${childPos.top - parentPos.top}px, 0px)`,
    })
    if (f) addSelectedFilter(f)
    else setMenuState(1)

    setModel(true)
  }

  const handleDelete = (e, f) => {
    e.stopPropagation()

    setSelectedFilters(prevFilters => {
      const updatedFilters = prevFilters.filter(a => a.key !== f.key)
      apply(1, updatedFilters)
      return updatedFilters
    })
  }

  useEffect(() => {
    if (selectedFilter && selectedFilter !== "") {
      const selected = selectedFilters.find(obj => obj.key === selectedFilter.key)
      if (selected) setMenuState(2)
    }
  }, [selectedFilter, selectedFilters])

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && selectedFilter) {
      addSelectedFilter(selectedFilter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (model) document.body.style.overflow = "auto"
    else document.body.style.overflow = "auto"

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [model])
  useEffect(()=>{
    setSelectedFilters(selectedFilterValues)
  },[selectedFilterValues])
  return (
    <>
      <div className={`filter-backdrop ${model ? "show" : ""}`} onClick={() => setModel(!model)} ></div>
      <div id='filter-parent' ref={parentRef}>
        <button
          type='button'
          className={`btn btn-sm btn-flex btn-color-gray-700 bg-white btn-active-color-primary fw-bold btn-sm btn-outline border-color-grey-700`}
          ref={ref}
          onClick={e => openFilterModel(e)}
        >
          <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-5' />
          Filter
        </button>

        {selectedFilters && selectedFilters.length
          ? selectedFilters.map((f, i) => (
              <Fragment key={i}>
                {f.key !== "offer_status" && (
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-sm btn-color-gray-700 bg-white btn-active-color-primary fw-bold border-color-grey-700 btn-outline mx-2 mr-0'
                    onClick={e => openFilterModel(e, f)}
                  >
                    <div onClick={e => handleDelete(e, f)}>
                      <KTSVG path='/media/icons/duotune/arrows/arr015.svg' className='svg-icon-4 text-danger' />
                    </div>
                    {f.label} ({f.values.length})
                  </button>
                )}
              </Fragment>
            ))
          : ""}
      </div>
      <div
        className={`menu menu-sub menu-sub-dropdown w-250px w-md-300px ${model === true ? "show" : "hide"}`}
        style={modelStyle}
        id='filter-box'
      >
        <div className={`${menuState && menuState === 1 ? "d-block" : "d-none"}`}>
          <div className='separator border-gray-200' />
          <div className='py-2'>
            {filters && filters.length
              ? filters.map((filter, i) => {
                  return (
                    <div key={i} className='cursor-pointer px-4 py-3 bg-hover-light-primary' onClick={() => addSelectedFilter(filter)}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex flex-column'>
                          <div className='fs-6 fw-bold cursor'>
                            <span className='fs-7 text-dark fw-bold'>{filter.label}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              : ""}
          </div>
        </div>
        <div className={`${menuState && menuState === 2 ? "d-block" : "d-none"}`}>
          {!loading ? (
            <Fragment>
              <div className='separator border-gray-200'></div>
              <div className='px-5 py-5'>
                <div className='positon-relative'>
                  {!searchLoading ? (
                    <KTSVG
                      path='/media/icons/duotune/general/gen021.svg'
                      className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute pt-2 px-2'
                    />
                  ) : (
                    <span className='spinner-border align-middle position-absolute spinner-border-sm mt-3 mx-3'></span>
                  )}
                  <input
                    type='text'
                    className='form-control bg-body ps-10 fs-7 form-control-sm border-color-grey-700'
                    name='search'
                    readOnly={searchLoading}
                    value={searchTerm}
                    autoFocus
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                </div>

                <div className='mh-250px h-250px y-scroll mt-5' style={{ overflow: "scroll" }}>
                  {filterOptions && filterOptions.length ? (
                    filterOptions.map((filter, i) => {
                      return (
                        <div className='d-flex align-items-center mb-1' key={i}>
                          <div className='d-flex flex-column'>
                            <li className='fs-6 fw-bold cursor'>
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input h-15px w-15px br-none'
                                  type='checkbox'
                                  value={filter.value}
                                  id={`filter-2-${i}`}
                                  onChange={() => updateFilters(filter.value)}
                                  checked={checkIfSelected(filter.value, selectedFilters)}
                                />
                                <label className='form-check-label fs-7' htmlFor={`filter-2-${i}`}>
                                  {filter.text}
                                </label>
                              </div>
                            </li>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className='text-center mt-5'>No data found</div>
                  )}
                </div>
                <div className='d-flex justify-content-end pt-7'>
                  <button className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2' onClick={() => goBack()}>
                    Back
                  </button>
                  <button className='btn btn-sm fw-bolder btn-primary' onClick={() => apply(1, selectedFilters)}>
                    Apply
                  </button>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className='d-flex justify-content-center align-items-center min-h-250px'>
              <span className='spinner-border spinner-border-md align-middle' />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export { FilterComponent }
