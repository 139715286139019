import React, { Fragment, useCallback, useEffect, useState, useRef } from "react"
import clsx from "clsx"
import { Link } from "react-router-dom"
import * as Yup from "yup"
// import _ from "lodash"
import Select from "react-select"
import { KTSVG, useDebounce } from "../../../_metronic/helpers"
import DataTable from "react-data-table-component"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { toast } from "react-toastify"
import { useThemeMode } from "../../../_metronic/partials"
import axios from "axios"
// import { FilterComponent } from "../shared-components/FilterComponent"
import ToolbarWrapper from "../shared-components/ToolbarWrapper"
// import ConfirmationModal from "../shared-components/ConfirmationModal"
// import PopOverToolTip from "../utils/PopOverTooltip"
import { Can /* , useCasl */ } from "../auth/CaslContext"

import "flag-icons/css/flag-icons.min.css"
import CustomToast from "../shared-components/CustomToast"
import { parseErrorToArray } from "../utils/ParseErrors"
import Tooltip from "../utils/PopOverTooltip"
import { Button, Modal } from "react-bootstrap"
import { useFormik } from "formik"
import { useAuth } from "../auth"


const TagOptions = [
  { label: "Auto", value: "auto" },
  { label: "Manual", value: "manual" }
]

const statusOptions = [
  { label: "All", value: 999 },
  { label: "Active", value: 1 },
  { label: "Disabled", value: 0 },
  { label: "Deleted", value: 2 }
]

const API_URL = process.env.REACT_APP_API_URL

const ArrowDownward = () => <i className='las la-arrow-down' />

const LinearIndeterminate = () => (
  <div className='d-flex justify-content-center align-items-center h-350px'>
    <span className='spinner-border spinner-border-md align-middle ms-2' />
  </div>
)

const initValues = {
  tag_id: "",
  tag_name: "",
  tag_options: "auto",
  tag_offers: []
}


const TagsTable = ({ toolbar = true }) => {
  const {currentUser} = useAuth()
  const statusDropdownRef = useRef(null)
  const statusDropdownBtnRef = useRef(null)
  const theme = useThemeMode()
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortBy, setSortBy] = useState("tag_id")
  const [sortOrder, setSortOrder] = useState("desc")
  const [searchTerm, setSearchTerm] = useState("")
  // const debouncedSearch = useDebounce(searchTerm, 500)
  const [selectedTagId, setSelectedTagId] = useState(null)
  const [addSourceModel, setAddSourceModel] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalId, setModalId] = useState(null)
  const [countryList,setCountryList] = useState([])

  const [status, setStatus] = useState(statusOptions[0])

  const [tagOffers, setTagOffers] = useState([])
  const [tagTotalRows, setTagTotalRows] = useState(0)
  const [tagOffersPage, setTagOffersPage] = useState(1)
  const [tagPerPage, setTagPerPage] = useState(10)
  // const [tagCurrentPage, setTagCurrentPage] = useState(1)
  const [tagSearch, setTagSearch] = useState("")

  const [tagCountry, setTagCountry] = useState("")
  const [tagOs, setTagOs] = useState("")
  const [domain,setDomain] = useState("")

  const [filterCountries,setFilterCountries] = useState([])
  const [os,setOs] = useState([])

  const [users,setUsers] = useState([])
  const [filterUser,setFilterUser] = useState([])


  const [selectedRows, setSelectedRows] = useState([])
 
  //const [toggledClearRows, setToggleClearRows] =useState(false);

  const fetchTagOffers = useCallback(
    async (page = tagOffersPage, country, os, search = "", tag_id = "") => {
      setLoading(true)
      return axios
        .get(`${API_URL}/tag/offers?country=${country}&os=${os}`, {
          params: {
            tag_id: selectedTagId?.tag_id || tag_id,
            limit: tagPerPage,
            page,
            order: "desc",
            sort: "id",
            search,
            // status: status?.value,
            start: (page - 1) * tagPerPage
          }
        })
        .then(({ data }) => data)
        .then(response => {
          const { data, success } = response
          if (success) {
            setTagOffers(data?.offers)
            setTagTotalRows(data?.payload?.pagination?.total)
          }
        })
        .catch(err => {
          console.error(err.message)
          setTagOffers([])
          setTagTotalRows(0)
          toast.error(<CustomToast messages={parseErrorToArray(err)} />)
        })
        .finally(() => setLoading(false))
    },
    [tagOffersPage, tagPerPage, selectedTagId?.tag_id]
  )

  const { errors, touched, setFieldValue, values, submitForm, isSubmitting, resetForm } = useFormik({
    initialValues: initValues,
    onSubmit: async values => {
      try {
        if (values?.tag_options === "manual" && selectedRows?.length === 0) {
          toast.error(<CustomToast messages={["Please select atleast one offer"]} />)
          return
        }
        const { data } = await axios.post(`${API_URL}/tag/mapping/${selectedTagId?.tag_id}`, {
          tag_option: values?.tag_options,
          offer_id: values?.tag_options === "manual" ? selectedRows : undefined
        })

        toast.success(<CustomToast messages={[data.message]} />)
        fetchTags(currentPage,searchTerm,filterCountries,os)
        closeModal()
      } catch (err) {
        toast.error(<CustomToast messages={parseErrorToArray(err)} />)
      }
    }
  })

  const formik1 = useFormik({
    initialValues: {
      name: "",
      aff_id: ""
    },
    validationSchema: Yup.object({
      name: Yup.string("source name should be string").required().label("Source Name"),
      aff_id: Yup.number("affiliate_id should be numeric id").required().label("Affiliate ID")
    }),
    onSubmit: async (values, helpers) => {
      const { data } = await axios.post(`${API_URL}/tag/source/create`, values)
      closeAddSourceModal()
      toast.success(data.message)
    }
  })

  const openModal = offer => {
    setTagCountry(offer?.country)
    setTagOs(offer?.os)
    if (offer?.tag_offers?.length) {
      fetchTagOffers(1, offer?.country, offer?.os, "", offer?.tag_id)

      setFieldValue("tag_options", TagOptions[1].value)
      setFieldValue("tag_offers", offer?.tag_offers || [])
      setSelectedRows(offer?.tag_offers.map(e => e.offer_id))
    }
    setSelectedTagId(offer)
  }

  const handleShow = id => {
    setModalId(id)
    setShowDeleteModal(true)
  }

  const handleClose = () => {
    setShowDeleteModal(false)
    setModalId(null)
  }

  const closeModal = () => {
    setSelectedTagId(null)
    resetForm(initValues)
  }

  const closeAddSourceModal = () => {
    formik1.resetForm()
    setAddSourceModel(false)
  }

  const deleteTag = async id => {
    const { data } = await axios.delete(`${API_URL}/tag/delete/${id}`)
    if (data?.success) {
      toast.success(data?.message)
    } else {
      toast.error(data?.err)
    }

    fetchTags(currentPage,searchTerm, filterCountries,os,filterUser)
    handleClose()
  }

  const handlePageChange = page => setCurrentPage(page)

  const handleTagPageChange = page => console.log(page) || setTagOffersPage(page)

  useEffect(() => {
    axios
      .get(`${API_URL}/tag/source/domain`)
      .then(({ data: res }) => {
      // setUserData(res?.user)
      setDomain(res?.user?.domain)
      })
      .catch(console.error)
  }, [])

  const fetchUsers = () => {
    if (currentUser.role_id == 1) {
      return axios
        .get(`${API_URL}/users/all-users`)
        .then(res => {
          const { data } = res
          console.log(data)
          setUsers(data?.data)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  useEffect(() => {
    fetchUsers()
    return () => {
      return 1
    }
  }, [])


  const fetchTags = useCallback(
    async (page, search, filterCountries,os, filterUsers ) => {
      setLoading(true)
      let country  = ''
      if(filterCountries && (filterCountries !='' || filterCountries.length>0)) {
        country = filterCountries.value
      }

      let pf  = ''
      if(os && (os !='' || os.length>0)) {
        pf = os.value
      }

      let uid = ""
      if (filterUsers && (filterUsers != "" || filterUsers.length > 0)) {
        uid = filterUsers.value
      }


      return axios
        .get(`${API_URL}/tag`, {
          params: {
            limit: perPage,
            page,
            order: sortOrder,
            sort: sortBy,
            search: search,
            status: status && status?.value!= '999' ? status?.value : undefined,
            start: (page - 1) * perPage,
            country:country,
            os:pf,
            filter_user: uid
            //filters: JSON.stringify(f),
          }
        })
        .then(({ data }) => data)
        .then(response => {
          const { data, success } = response
          if (success) {
            setData(data?.tags)
            setTotalRows(data?.payload?.pagination?.total)
          }
        })
        .catch(err => {
          console.error(err.message)
          toast.error(<CustomToast messages={parseErrorToArray(err)} />)
        })
        .finally(() => setLoading(false))
    },
    [perPage, sortOrder, sortBy, status]
  )

  const handleSort = (column, sortDirection) => {
    setSortBy(column.sortField)
    setSortOrder(sortDirection)
  }

  useEffect(() => {
    if (selectedTagId?.tag_id) {
      fetchTagOffers(tagOffersPage, tagCountry, tagOs, "", selectedTagId?.tag_id)
    }
  }, [tagOffersPage, tagCountry, tagOs, selectedTagId?.tag_id, fetchTagOffers])

  useEffect(() => {
    fetchTags(currentPage, searchTerm, filterCountries,os,filterUser)
  }, [currentPage, fetchTags])

  const handleCopy = (text, result) => {
    toast.info(<CustomToast messages={["copied to clipboard"]} />)
  }

  const updateSelectedRows = id => {
    const selected = [...selectedRows]
    const index = selected.indexOf(id)
    if (index === -1) {
      selected.push(id)
    } else {
      selected.splice(index, 1)
    }
    setSelectedRows(selected)
  }
  const tagColumns = [
    {
      name: "",
      selector: row => `${row.offer_id}`,
      sortable: true,
      sortField: "value",
      center: false,
      width: "50px",
      cell: (row, i) => {
        return (
          <>
            <div className='form-check form-check-custom form-check-solid' key={i}>
              <input
                className='form-check-input'
                type='checkbox'
                id={`row_id_${row.value}`}
                checked={selectedRows.indexOf(row.offer_id) >= 0 ? true : false}
                onChange={() => updateSelectedRows(row.offer_id)}
              />
              <label className='form-check-label' htmlFor={`row_id_${row.offer_id}`}></label>
            </div>
          </>
        )
      }
    },
    {
      name: "ID",
      selector: row => `${row.offer_id}`,
      sortable: true,
      sortField: "offer_id",
      width: "100px",
      center: false,
      style: { padding: "10px 1.5rem", minWidth: "auto" }
    },
    {
      name: "Offer name",
      selector: row => `${row.label}`,
      sortable: true,
      width: "400px",
      sortField: "offer_name",
      center: false
      // cell: (row, i) => <TagIdAdCode tag={row} onCopy={handleCopy} />
    },
    {
      name: "Country",
      selector: row => `${row.country}`,
      center: false
      // cell: (row, i) => <TagIdAdCode tag={row} onCopy={handleCopy} />
    },
    {
      name: "OS",
      selector: row => `${row.platform}`,
      center: false
      // cell: (row, i) => <TagIdAdCode tag={row} onCopy={handleCopy} />
    },
    {
      name: "Status",
      selector: row => `${row.status}`,
      center: true,
      cell: (row, i, column) => {
        switch (row.status) {
          case 0:
            return <label className='badge badge-light-danger badge-sm'>Disabled</label>
          case 1:
            return <label className='badge badge-light-success badge-sm mx-1'>Active</label>
          case 2:
            return <label className='badge badge-light-warning badge-sm'>Paused</label>
          default:
            return <label className='badge badge-light-warning badge-sm'>Paused</label>
        }
      }
    }
  ]
  const columns = [
    {
      name: "ID",
      selector: row => `${row.tag_id}`,
      sortable: true,
      sortField: "tag_id",
      // width: showAdCode ? "350px" : "200px",
      center: true,
      style: { padding: "10px 1.5rem", minWidth: "auto" },
      cell: (row, i) => <TagIdAdCode tag={row} onCopy={handleCopy} domain={domain}/>
    },
    {
      name: "User",
      center: false,
      className: "froxen",
      width: "200px",
      omit: currentUser?.role_id == 2 ? true : false,
      style: { display: "flex", justifyContent: "space-between" },
      cell: (row, i, column) => (
        <>
          <span className="text-break">{`${row?.taggedUser?.name} (${row?.taggedUser?.email})`}</span>
        </>
      )
    },
    {
      name: "Banner",
      center: true,
      cell: row =>
        row.banner && <img className='rounded rounded-1 shadow' src={`https://${row.banner}`} alt={""} height={row.height * 0.5} width={row.width * 0.5} />
    },
    
    {
      name: "Banner Size",
      width: "120px",
      cell: tag => <span className='overflow-hidden'>{`${tag?.width}x${tag?.height} px`}</span>
    },
    {
      name: "Tag Name",
      selector: row => `${row.tag_name}`,
      sortable: true,
      sortField: "tag_name",
      width: "120px",
      style: { display: "flex", justifyContent: "space-between", overflow: "hidden !important" },
      className: "froxen",
      cell: (row, i, column) => (
        <>
          <span className='overflow-hidden'>{`${row?.tag_name}`}</span>
        </>
      )
    },
    {
      name: "Status",
      selector: row => `${row.is_active}`,
      center: true,
      width: "100px",
      style: { maxWidth: "100px" },
      cell: (row, i, column) => {
        switch (row.is_active) {
          case 1:
            return <label className='badge badge-light-success badge-sm mx-1'>Active</label>
          case 2:
              return <label className='badge badge-light-danger badge-sm mx-1'>Deleted</label>
          default:
            return <label className='badge badge-light-warning badge-sm'>Disabled</label>
        }
      }
    },
    {
      name: "Source",
      selector: t => t.traffic_source,
      center: true,
      width: "100px",
      style: { flexWrap: "wrap", justifyContent: "center", fontWeight: 600 },
      cell: t => <span className='badge badge-sm badge-light-info'>{t.source.name}</span>
    },
    {
      name: "Country",
      selector: row => `${row.country}`,
      center: true,
      width: "115px",
      style: { flexWrap: "wrap", justifyContent: "center", fontWeight: 600 },
      cell: (row, i, column) => {
        return row?.country
      }
    },
    {
      name: "OS",
      selector: tag => `${tag.os}`,
      center: true,
      width: "80px",
      style: { flexWrap: "wrap", justifyContent: "center", fontWeight: 600 },
      cell: tag => tag?.os
    },
    {
      name: "Actions",
      center: true,
      cell: row => {
        const Actions = offer => {
          return (
            <div className='d-flex flex-col gap-1 w-100 align-items-center justify-content-center'>
              {offer.is_active === 1 && (
                <button
                  onClick={() => openModal(offer)}
                  className='btn btn-sm btn-icon btn-light-success h-25px'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Attach Offers'
                >
                  <span className='las la-link fs-5' />
                </button>
              )}

              <Tooltip title='View Report' position='top'>
                <Link
                  to={`/reports/${row.tag_id}`}
                  className='btn btn-sm btn-icon btn-light-primary h-25px ms-2'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Reports'
                >
                  <span className='las la-chart-bar fs-5' />
                </Link>
              </Tooltip>

              <Tooltip title='Copy click url' position='top'>
                <CopyToClipboard text={row.url} onCopy={handleCopy}>
                  <button className='btn btn-sm btn-icon btn-light-info h-25px ms-2'>
                    <span className='las la-copy fs-5' />
                  </button>
                </CopyToClipboard>
              </Tooltip>

              {
                row?.is_active != '2' && (
              <Link
                to={`/tags/edit/${row.tag_id}`}
                className='btn btn-sm btn-icon btn-light-primary h-25px ms-2'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Edit Tag'
              >
                <span className='las la-edit fs-5' />
              </Link>)
        }
              
                {row?.is_active != '2' && (
                   <button
                   onClick={() => handleShow(row.tag_id)}
                   className='btn btn-sm btn-icon btn-light-danger h-25px ms-2'
                   data-bs-toggle='tooltip'
                   data-bs-placement='top'
                   data-bs-trigger='hover'
                   title='Delete Tag'
   
                 >
                   <span className='las la-trash fs-5' />
                 </button>
                )}
             
            </div>
          )
        }
        return Actions(row)
      }
    }
  ]

  useEffect(() => {
    // Fetch country data
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/countries`);
        if (response.data && response.data.data && response.data.data.countries) {
          setCountryList(response.data.data.countries);
        } else {
          setCountryList([]);
        }
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };
  
    // Call the fetch function
    fetchData();
  
    // Return a cleanup function (or undefined)
    return () => {
      return undefined
    };
  }, []);

  return (
    <Fragment>
      {toolbar && (
        <ToolbarWrapper>
          <div className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
            <h3>Tags</h3>
          </div>
          <div className='d-flex flex-row align-items-center gap-3 min-w-300px justify-content-end'>
            <div>
              <button
                ref={statusDropdownBtnRef}
                data-kt-menu-trigger="{default: 'hover'}"
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                className='btn btn-sm btn-light-primary btn-active-light-primary'
              >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-5' />
                {status?.label || "All"}
              </button>
              <div
                ref={statusDropdownRef}
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
                data-kt-menu='true'
              >
                {statusOptions.map((obj, i) => (
                  <div
                    className={clsx("menu-item px-3 bg-hover-light-primary", {
                      "bg-light-primary hoverable": status && status.value === obj.value
                    })}
                    key={`filter_option_${i}`}
                  >
                    <div className='menu-content d-flex align-items-center px-3 cursor-pointer' onClick={e => setStatus(obj)}>
                      {obj.label}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Can do='create' on='offers'>
              <Link to={"/tags/create"} role='button' className='btn btn-sm btn-primary px-3 d-inline-flex align-items-center'>
                <i className='las la-plus-circle fs-2' /> Add Tags
              </Link>
            </Can>
          </div>
        </ToolbarWrapper>
      )}

      <div className='row mb-5'>
        <div className='col mr-0'>
          <div className='position-relative'>
            <div className='input-group input-group-solid border border-color-gray-700'>
              <span className='input-group-text ps-6 bg-white border-0 cursor-pointer'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-3 svg-icon-gray-00 position-absolute top-50 translate-middle'
                />
              </span>
              <input
                type='text'
                className='form-control bg-white border-0'
                placeholder='Search by tag name/id'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={e => e.key === "Enter" && fetchTags(1, searchTerm, filterCountries,os,filterUser)}
              />

              {searchTerm?.length > 0 && (
                <span className='input-group-text ps-6 bg-white cursor-pointer' onClick={() => setSearchTerm("")}>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr015.svg'
                    className='svg-icon-3 position-absolute bg-body text-danger translate-middle top-50'
                  />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className=' col mr-0'>
          
          {countryList.length && (
            <Select
              searchble={true}
              isClearable={true}
              name='countries'
              options={countryList}
              className='border-0'
              classNamePrefix='select'
              placeholder="Select country"
              onChange={options => setFilterCountries(options)}
              menuPortalTarget={document.body} 
              isDisabled={countryList.length == 0}
            />
          )}
        </div>

        <div className='col mr-0'>
          
            <Select
              searchble={true}
              name='os'
              isClearable={true}
              options={[
                  {
                    value:"Android",
                    label:"Android"
                  },
                  {
                    value:"iOS",
                    label:"iOS"
                  },

              ]}
              className='border-0'
              classNamePrefix='select'
              placeholder="Select platform"
              onChange={option => setOs(option)}
              menuPortalTarget={document.body} 
         
            />
        
        </div>
        {currentUser &&
          currentUser?.role_id == 1 && (
              <div className='col mr-0'>
                <Select
                  searchble={true}
                  name='users'
                  isClearable={true}
                  options={users}
                  className='border-0'
                  classNamePrefix='select'
                  placeholder='Select user'
                  onChange={option => setFilterUser(option)}
                  menuPortalTarget={document.body}
                />
              </div>
            )}

        <div className=' col mr-0'>
          <button className="btn btn-primary btn-block" onClick={()=>fetchTags(1, searchTerm,filterCountries,os, filterUser)}>Apply filter</button>
        </div>
      </div>

      <div className={`mb-5 mb-xl-8`}>
        <DataTable
          keyField='tag_id'
          pagination
          fixedHeader
          columnsResize
          persistTableHead
          paginationServer
          data={data}
          columns={columns}
          theme={theme.mode}
          onSort={handleSort}
          progressPending={loading}
          paginationRowsPerPageOptions={[5, 10, 15, 25, 50]}
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setPerPage}
          sortIcon={<ArrowDownward />}
          progressComponent={<LinearIndeterminate />}
          style={{ overflowX: "auto", minHeight: "500px" }}
          customStyles={{ tableWrapper: { style: { display: "grid", overflowX: "scroll" } } }}
        />
      </div>

      <Modal show={selectedTagId !== null} onHide={closeModal} size={"xl"} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>Attach Offer: Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Tag ID</label>
            <div className='col-lg-6'>
              <div className='row'>
                <input className='form-control' type='text' readOnly value={selectedTagId?.tag_id} />
              </div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Tag Name</label>
            <div className='col-lg-6'>
              <div className='row'>
                <input className='form-control' type='text' readOnly value={selectedTagId?.tag_name} />
              </div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label  fw-semibold fs-6'>Tag Option</label>
            <div className='col-lg-6 px-0 py-0'>
              <Select
                options={TagOptions}
                className='basic-select'
                classNamePrefix='select'
                defaultValue={TagOptions[0]}
                value={TagOptions.find(op => op.value === values.tag_options)}
                onChange={val => {
                  if (val.value === "manual") {
                    setFieldValue("tag_options", val.value)
                    fetchTagOffers(1, tagCountry, tagOs)
                  } else {
                    setFieldValue("tag_options", val.value)
                    setSelectedRows([])
                  }
                }}
              />
            </div>

            {errors?.offer_details?.tag_options && touched?.offer_details?.tag_options && (
              <span className='text-danger'>{errors?.offer_details?.tag_options}</span>
            )}
          </div>

          {values?.tag_options === "manual" && (
            <>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>Select offers to attach</label>
                <div className='col-lg-6'>
                  <div className='row'>
                    <input
                      className='form-control'
                      type='text'
                      placeholder='Enter text to search offers'
                      value={tagSearch}
                      onChange={e => setTagSearch(e.target.value)}
                      onKeyDown={e => e.key === "Enter" && fetchTagOffers(1, tagCountry, tagOs, tagSearch)}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <DataTable
                  pagination
                  fixedHeader
                  columnsResize
                  persistTableHead
                  paginationServer
                  data={tagOffers}
                  columns={tagColumns}
                  theme={theme.mode}
                  progressPending={loading}
                  paginationRowsPerPageOptions={[10]}
                  paginationTotalRows={tagTotalRows}
                  onChangePage={handleTagPageChange}
                  onChangeRowsPerPage={setTagPerPage}
                  sortIcon={<ArrowDownward />}
                  progressComponent={<LinearIndeterminate />}
                  style={{ overflowX: "auto", minHeight: "500px" }}
                  customStyles={{ tableWrapper: { style: { display: "grid", overflowX: "scroll" } } }}
                  // selectableRows
                  // onSelectedRowsChange={onRowChange}
                  // clearSelectedRows={handleClearRows}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={submitForm} disabled={isSubmitting}>
            Submit
          </Button>
          <Button variant='secondary' onClick={closeModal}>
            Close
          </Button>
          {/* Add your save or update button here */}
        </Modal.Footer>
      </Modal>

      {/* Add Source Modal */}
      <Modal show={addSourceModel} onHide={closeAddSourceModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Source:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik1.handleSubmit}>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6 required'>Source Name:</label>
              <div className='col-lg-6'>
                <div className='row'>
                  <input className='form-control' type='text' placeholder='Enter source name' {...formik1.getFieldProps("name")} />
                </div>
                {formik1.touched?.name && formik1.errors?.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='fs-8 text-bold'>
                        {formik1.errors.name}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6 required'>Affiliate ID:</label>
              <div className='col-lg-6'>
                <div className='row'>
                  <input className='form-control' type='text' placeholder='Provide Affiliate ID' {...formik1.getFieldProps("aff_id")} />
                </div>
                {formik1.touched?.aff_id && formik1.errors?.aff_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='fs-8 text-bold'>
                        {formik1.errors.aff_id}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={formik1.submitForm} disabled={formik1.isSubmitting}>
            Submit
          </Button>
          <Button variant='secondary' onClick={closeAddSourceModal} disabled={formik1.isSubmitting}>
            Close
          </Button>
          {/* Add your save or update button here */}
        </Modal.Footer>
      </Modal>
      {/* END Add Source Modal */}

      {/* Open Delete Modal */}
      <Modal show={showDeleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>Are you sure you want to delete this tag?</b>
          </p>
          <p className='text-danger'>This action can not be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' size='sm' onClick={() => deleteTag(modalId)} disabled={formik1.isSubmitting}>
            Delete
          </Button>
          <Button variant='secondary' size='sm' onClick={handleClose} disabled={formik1.isSubmitting}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

const TagIdAdCode = ({ tag, onCopy, domain }) => {
  const [showAdCode, setShowAdCode] = useState(false)
  const adCode = `<script async src="https://${domain}/sja/${tag.tag_id}?w=${tag.width}&h=${tag.height}${
    tag.source.dsp.tag_params || ""
  }"></script>`

  return (
    <div style={{ minWidth: showAdCode ? "350px" : "200px", display: "flex", alignItems: "center" }}>
      <div className={`row ${!showAdCode && "w-100"}`}>
        <button className={`btn btn-sm btn-icon col-2`} onClick={e => setShowAdCode(p => !p)}>
          <i className={`las la-${showAdCode ? "minus" : "plus"}-circle ${showAdCode ? "text-danger" : "text-success"}`} />
        </button>

        {!showAdCode && (
          <Tooltip placement='right' title='click to copy'>
            <CopyToClipboard text={tag.tag_id} onCopy={onCopy} options={{ message: "copied to clipboard" }}>
              <span className='badge badge-sm badge-secondary cursor-pointer col-8 justify-content-center'>{tag.tag_id?.slice(0, 8)}</span>
            </CopyToClipboard>
          </Tooltip>
        )}
      </div>

      {showAdCode && (
        <div className='position-relative w-100'>
          <textarea className='form-control' rows={4} defaultValue={adCode} readOnly />
          <CopyToClipboard text={adCode} onCopy={(t, r) => toast.info(<CustomToast messages={["Ad script copied!"]} />)}>
            <button
              className='btn btn-sm btn-light-primary btn-outline-primary w-55px h-25px p-0'
              style={{ position: "absolute", top: "8px", right: "8px" }}
            >
              copy
            </button>
          </CopyToClipboard>
        </div>
      )}
    </div>
  )
}

export default TagsTable
