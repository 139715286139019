import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import clsx from "clsx"
import { KTSVG, useDebounce } from "../../../_metronic/helpers"
import DataTable from "react-data-table-component"
// import { deleteOffer } from "./core/_requests"
import { toast } from "react-toastify"
import { useThemeMode } from "../../../_metronic/partials"
import axios from "axios"
import ToolbarWrapper from "../shared-components/ToolbarWrapper"
import { Can /* , useCasl */ } from "../auth/CaslContext"
import "flag-icons/css/flag-icons.min.css"
import CustomToast from "../shared-components/CustomToast"
import { parseErrorToArray } from "../utils/ParseErrors"
import Select from "react-select"
import { filter } from "lodash"
import { useAuth } from "../auth"

const statusOptions = [
  { label: "All", value: 999 },
  { label: "Disabled", value: 0 },
  { label: "Active", value: 1 },
  { label: "Paused", value: 2 }
]

const API_URL = process.env.REACT_APP_API_URL

const ArrowDownward = () => <i className='las la-arrow-down' />

const LinearIndeterminate = () => (
  <div className='d-flex justify-content-center align-items-center h-350px'>
    <span className='spinner-border spinner-border-md align-middle ms-2' />
  </div>
)

const OfferTable = ({ toolbar = true }) => {
  const { currentUser } = useAuth()
  const statusDropdownRef = useRef(null)
  const statusDropdownBtnRef = useRef(null)
  const theme = useThemeMode()
  const [data, setData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortBy, setSortBy] = useState("offer_id")
  const [sortOrder, setSortOrder] = useState("desc")
  const [searchTerm, setSearchTerm] = useState("")
  const [status, setStatus] = useState(statusOptions[0])
  const debouncedSearch = useDebounce(searchTerm, 500)
  const [countryList, setCountryList] = useState([])
  const [filterCountries, setFilterCountries] = useState([])
  const [os, setOs] = useState([])
  const [users, setUsers] = useState([])
  const [filterUser, setFilterUser] = useState([])

  const handlePageChange = page => setCurrentPage(page)

  const fetchOffers = useCallback(
    async (page, filters = [], searchText, filterCountries, os, filterUser) => {
      setLoading(true)
      // const f = filters.map(obj => ({ key: obj.key, values: obj.values }))

      let country = ""
      if (filterCountries && (filterCountries != "" || filterCountries.length > 0)) {
        country = filterCountries.value
      }

      let pf = ""
      if (os && (os != "" || os.length > 0)) {
        pf = os.value
      }

      let uid = ""
      if (filterUser && (filterUser != "" || filterUser.length > 0)) {
        uid = filterUser.value
      }

      let offer_status = undefined
      if (status.value != "999") {
        offer_status = status?.value
      }
      return axios
        .get(`${API_URL}/offers`, {
          params: {
            limit: perPage,
            page,
            order: sortOrder,
            sort: sortBy,
            search: searchText,
            status: offer_status,
            start: (page - 1) * perPage,
            country: country,
            os: pf,
            filter_user: uid
          }
        })
        .then(({ data }) => data)
        .then(response => {
          const { data, success } = response
          if (success) {
            setData(data?.offers)
            setTotalRows(data?.payload?.pagination?.total)
          }
        })
        .catch(err => {
          console.error(err.message)
          toast.error(<CustomToast messages={parseErrorToArray(err)} />)
        })
        .finally(() => setLoading(false))
    },
    [perPage, sortOrder, sortBy, status]
  )

  const handleSort = (column, sortDirection) => {
    setSortBy(column.sortField)
    setSortOrder(sortDirection)
  }

  useEffect(() => {
    if (statusDropdownBtnRef.current) statusDropdownBtnRef.current.classList.remove("show", "menu-dropdown")
    if (statusDropdownRef.current) {
      statusDropdownRef.current.classList.remove("show")
      statusDropdownRef.current.removeAttribute("style")
    }
  }, [status])

  useEffect(() => {
    fetchOffers(currentPage, [], searchTerm, filterCountries, os,filterUser)
  }, [currentPage, fetchOffers, status])

  const fetchUsers = () => {
    if (currentUser.role_id == 1) {
      return axios
        .get(`${API_URL}/users/all-users`)
        .then(res => {
          const { data } = res
          setUsers(data?.data)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  useEffect(() => {
    fetchUsers()
    return () => {
      return 1
    }
  }, [])

  const columns = [
    {
      name: "Offer id",
      selector: row => `${row.offer_id}`,
      sortable: true,
      sortField: "id",
      width: "75px",
      cell: (row, i) => row.offer_id
    },
    {
      name: "App Icon",
      center: true,
      cell: row => row.icon && <img className='rounded rounded-1 shadow' src={row.icon} alt={""} height={35} width={35} />
    },
    {
      name: "User",
      center: false,
      className: "froxen",
      width: "200px",
      omit: currentUser?.role_id == 2 ? true : false,
      style: { display: "flex", justifyContent: "space-between" },
      cell: (row, i, column) => (
        <>
          <span className="text-break">{`${row?.user?.name} (${row?.user?.email})`}</span>
        </>
      )
    },
    {
      name: "Campaign Name",
      selector: row => `${row.name}`,
      sortable: true,
      sortField: "name",
      width: "250px",
      style: { display: "flex", justifyContent: "space-between", overflow: "hidden !important" },
      className: "froxen",
      cell: (row, i, column) => (
        <>
          <span className='overflow-hidden'>{`${row?.name}`}</span>
        </>
      )
    },
    {
      name: "Bundle id",
      width: "125px",
      style: { wordWrap: "break-word", textWrap: "wrap" },
      cell: (row, i, column) => {
        // return row.bundle_id
        return <span className="text-break">{`${row?.bundle_id}`}</span>
      }
    },
    {
      name: "Status",
      selector: row => `${row.status}`,
      center: true,
      cell: (row, i, column) => {
        switch (row.status) {
          case 0:
            return <label className='badge badge-light-danger badge-sm'>Disabled</label>
          case 1:
            return <label className='badge badge-light-success badge-sm mx-1'>Active</label>
          case 2:
            return <label className='badge badge-light-warning badge-sm'>Paused</label>
          default:
            return <label className='badge badge-light-warning badge-sm'>Paused</label>
        }
      }
    },
    {
      name: "Country",
      selector: row => `${row.country}`,
      center: true,
      width: "115px",
      style: { flexWrap: "wrap", justifyContent: "center", fontWeight: 600 },
      cell: (row, i, column) => {
        return row?.country
      }
    },
    {
      name: "OS",
      selector: row => `${row.os}`,
      center: true,
      width: "125px",
      style: { flexWrap: "wrap", justifyContent: "center", fontWeight: 600 },
      cell: row => {
        return row?.platform
      }
    },
    {
      name: "Updated At",
      selector: row => `${row.updated_at}`,
      center: true,
      width: "205px",
      style: { flexWrap: "wrap", justifyContent: "center" },
      cell: (row, i, column) => {
        return new Date(row?.updated_at).toLocaleString("en-US")
      }
    },
    {
      name: "Actions",
      center: true,
      cell: row => {
        const Actions = offer => {
          return (
            <div className='d-flex flex-col gap-1 w-100 align-items-center justify-content-center'>
              <Can do='edit' this='offers'>
                <Link to={"/offers/edit/" + offer.offer_id} className='btn btn-sm btn-icon btn-light-primary w-30px h-25px'>
                  <span className='las la-edit fs-5' />
                </Link>
              </Can>
            </div>
          )
        }
        return Actions(row)
      }
    }
  ]

  useEffect(() => {
    // Fetch country data
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/countries`)
        if (response.data && response.data.data && response.data.data.countries) {
          setCountryList(response.data.data.countries)
        } else {
          setCountryList([])
        }
      } catch (error) {
        console.error("Error fetching country data:", error)
      }
    }

    // Call the fetch function
    fetchData()

    // Return a cleanup function (or undefined)
    return () => {
      return undefined
    }
  }, [])

  return (
    <Fragment>
      <ToolbarWrapper>
        <div className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
          <h3>Offers</h3>
        </div>
        <div className='d-flex flex-row align-items-center gap-3 min-w-300px justify-content-end'>
          <div>
            <button
              ref={statusDropdownBtnRef}
              data-kt-menu-trigger="{default: 'hover'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              className='btn btn-sm btn-light-primary btn-active-light-primary'
            >
              <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-5' />
              {status?.label || "All"}
            </button>
            <div
              ref={statusDropdownRef}
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
              data-kt-menu='true'
            >
              {statusOptions.map((obj, i) => (
                <div
                  className={clsx("menu-item px-3 bg-hover-light-primary", {
                    "bg-light-primary hoverable": status && status.value === obj.value
                  })}
                  key={`filter_option_${i}`}
                >
                  <div className='menu-content d-flex align-items-center px-3 cursor-pointer' onClick={e => setStatus(obj)}>
                    {obj.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ToolbarWrapper>

      <div className='row mb-5'>
        <div className='col mr-0'>
          <div className='position-relative'>
            <div className='input-group input-group-solid border border-color-gray-700'>
              <span className='input-group-text ps-6 bg-white border-0 cursor-pointer'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-3 svg-icon-gray-00 position-absolute top-50 translate-middle'
                />
              </span>
              <input
                type='text'
                className='form-control bg-white border-0'
                placeholder='Search by offer id/name/bundleid'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={e => e.key === "Enter" && fetchOffers(1, [], searchTerm, filterCountries, os, filterUser)}
              />

              {searchTerm?.length > 0 && (
                <span className='input-group-text ps-6 bg-white cursor-pointer' onClick={() => setSearchTerm("")}>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr015.svg'
                    className='svg-icon-3 position-absolute bg-body text-danger translate-middle top-50'
                  />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='col mr-0'>
          {countryList.length && (
            <Select
              searchble={true}
              isClearable={true}
              name='countries'
              options={countryList}
              className='border-0'
              classNamePrefix='select'
              placeholder='Select country'
              onChange={options => setFilterCountries(options)}
              menuPortalTarget={document.body}
              isDisabled={countryList.length == 0}
            />
          )}
        </div>

        <div className='col mr-0'>
          <Select
            searchble={true}
            name='os'
            isClearable={true}
            options={[
              {
                value: "Android",
                label: "Android"
              },
              {
                value: "iOS",
                label: "iOS"
              }
            ]}
            className='border-0'
            classNamePrefix='select'
            placeholder='Select platform'
            onChange={option => setOs(option)}
            menuPortalTarget={document.body}
          />
        </div>
        {currentUser &&
          currentUser?.role_id == 1 && (
              <div className='col mr-0'>
                <Select
                  searchble={true}
                  name='users'
                  isClearable={true}
                  options={users}
                  className='border-0'
                  classNamePrefix='select'
                  placeholder='Select user'
                  onChange={option => setFilterUser(option)}
                  menuPortalTarget={document.body}
                />
              </div>
            )}

        <div className='col mr-0 text-center'>
          <button className='btn btn-primary ' onClick={() => fetchOffers(1, [], searchTerm, filterCountries, os, filterUser)}>
            Apply filter
          </button>
        </div>
      </div>

      <div className={`mb-5 mb-xl-8`}>
        <DataTable
          pagination
          fixedHeader
          columnsResize
          persistTableHead
          paginationServer
          data={data}
          columns={columns}
          theme={theme.mode}
          onSort={handleSort}
          progressPending={loading}
          paginationRowsPerPageOptions={[5, 10, 15, 25, 50]}
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setPerPage}
          sortIcon={<ArrowDownward />}
          progressComponent={<LinearIndeterminate />}
          style={{ overflowX: "auto", minHeight: "500px" }}
          customStyles={{ tableWrapper: { style: { display: "grid", overflowX: "scroll" } } }}
        />
      </div>
    </Fragment>
  )
}

export default OfferTable
